import { memo } from 'react';

import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps, Link } from '@mui/material';

// ----------------------------------------------------------------------

interface LogoProps extends BoxProps {
  single?: boolean;
}

function Logo({ single = false, sx }: LogoProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const singleLogo = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 10 237 237.37" width="100%" height="100%">
      <g>
        <path
          d="M116.52,173.54H57.26l-9.46,28.13H2.24L64.98,42.33h43.57l62.74,159.35h-45.31l-9.46-28.13Zm-10.46-31.37l-19.17-57.51-19.17,57.51h38.34Z"
          fill="currentColor"
        />
        <circle cx="203.55" cy="170.17" r="30.89" fill={PRIMARY_MAIN} />
      </g>
    </svg>
  );

  const fullLogo = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 10 237 237.37" width="100%" height="100%">
      <g>
        <path
          d="M116.52,173.54H57.26l-9.46,28.13H2.24L64.98,42.33h43.57l62.74,159.35h-45.31l-9.46-28.13Zm-10.46-31.37l-19.17-57.51-19.17,57.51h38.34Z"
          fill="currentColor"
        />
        <circle cx="203.55" cy="170.17" r="30.89" fill={PRIMARY_MAIN} />
      </g>
    </svg>
  );

  return (
    <Link
      component={RouterLink}
      to="/"
      color="inherit"
      aria-label="go to homepage"
      sx={{ lineHeight: 0 }}
    >
      <Box
        sx={{
          width: single ? 100 : 60,
          lineHeight: 0,
          cursor: 'pointer',
          display: 'inline-flex',
          ...sx,
        }}
      >
        {single ? singleLogo : fullLogo}
      </Box>
    </Link>
  );
}

export default memo(Logo);
