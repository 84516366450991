export const NAV = {
  W_BASE: 260,
  W_DRAWER: 280,
  //
  H_ITEM: 48,
  H_ITEM_SUB: 36,
};

export const ICON = {
  NAV_ITEM: 24,
};

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
};

export const CONTACT_US_EMAIL = 'contact@achati.io';
export const CONTACT_US_PHONE = '+972-54-305-1516';
export const SUPPORT_EMAIL = 'contact@achati.io';

export const EMPTY_STRING = '\u00A0';
